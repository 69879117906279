import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  const FeatureCard: React.FC<{ title: string; description: string }> = ({ title, description }) => (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="">{description}</p>
    </div>
  );

  return (
    <div className="">
      <div className="navbar bg-base-100">
        <div className="flex-1">
          <a className="btn btn-ghost text-xl">CopilotPlanners</a>
        </div>
        <ul className="menu menu-horizontal px-1">
          <li><a>About</a></li>
          <li><a>Learn More</a></li>
        </ul>
      </div>

      
    <div className="min-h-screen flex flex-col items-center justify-center">
      {/* Hero Section */}
      <section className="w-full max-w-6xl px-4 py-20 text-center">
        <h1 className="text-5xl font-bold mb-6 leading-tight">
          Take Control of Your Finances
        </h1>
        <p className="text-lg mb-8">
          Copilot Planners makes financial planning simple, effective, and accessible to everyone.
        </p>
        <a
          href="#get-started"
          className="px-8 py-4 text-white font-semibold rounded-full transition"
        >
          Get Started
        </a>
      </section>

      {/* Features Section */}
      <section id="features" className="w-full max-w-6xl px-4 py-16 text-center">
        <h2 className="text-3xl font-semibold mb-4">Why Choose Copilot Planners?</h2>
        <p className=" mb-8">
          Our tool provides personalized insights, easy-to-understand analytics, and reliable guidance.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <FeatureCard
            title="Easy Setup"
            description="Get started quickly with our streamlined onboarding."
          />
          <FeatureCard
            title="Personalized Insights"
            description="Make informed decisions with insights tailored to your financial goals."
          />
          <FeatureCard
            title="Secure Data"
            description="We prioritize your privacy with industry-leading security."
          />
        </div>
      </section>

      {/* CTA Section */}
      <section id="get-started" className="w-full  py-16 px-4 text-center">
        <h2 className="text-3xl font-bold mb-4">
          Ready to Start Your Journey?
        </h2>
        <p className="text-lg mb-8">
          Join other users managing their finances with ease. Sign up for free!
        </p>
        <a
          href="/sign-up"
          className="px-8 py-4 text-white font-semibold rounded-full transition"
        >
          Sign Up Now
        </a>
      </section>
    </div>
    </div>
  );
}

export default App;
